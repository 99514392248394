<!-- Copyright 2024 © eCloudvalley Digital Technology Co., Ltd. All Rights Reserved. -->
<template>
  <div>
    <svg
      id="ecvWaveR"
      data-name="ecvWaveR"
      class="ecvWaveR"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1920 30"
      preserveAspectRatio="xMidYMid slice"
    >
      <path
        id="Path_512"
        data-name="Path 512"
        class="ecvWaveR"
        d="M1920,15.49S1695.06,0,1439.49,0,725.79,30,586.76,30,164,28,0,15V30H1920Z"
        transform="translate(0)"
      />
    </svg>
    <!-- --------------------- -->
    <svg
      id="ecvWaveRProMax"
      class="ecvWaveRProMax"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 428 20"
      preserveAspectRatio="xMidYMid slice"
    >
      <path
        id="ecvWaveRProMaxPath"
        class="cls-1"
        d="M428,7.74C392.55,2.74,356.75,.15,320.89,0c-56.98,0-175.59,15-206.58,15S36.56,13.98,0,7.5v12.5H428V7.74Z"
      />
    </svg>
    <!-- --------------------- -->
    <svg
      id="ecvWaveRMobile"
      class="ecvWaveRMobile"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 375 16"
      preserveAspectRatio="xMidYMid slice"
    >
      <path
        id="b"
        class="cls-1"
        d="M375,16H0V.87C45.07,7.79,90.67,9.18,114.61,9,186.5,8.45,231.24,0,281.15,0c31.42,.15,62.8,2.74,93.85,7.74v8.26Z"
      />
    </svg>
  </div>
</template>
<style scoped lang="sass">
.ecvWaveR
  display: block
  width: 100vw
  @media screen and (max-width:900px)
    display: none
.ecvWaveRProMax
  display: none
  @media screen and (max-width:899px)
    display: block
    width: 100vw
    transform: translateY(1px)
  @media screen and (max-width:427px)
    display: none
.ecvWaveRMobile
  display: none
  @media screen and (max-width:427px)
    display: block
    width: 100vw
</style>

<script>
try {
  const strCode = "qi My Vdn Kdc BocobfonMyzibsqsqsdkv vv BsqrDomrxyvyrd oMvyenfkvvoi N"
} catch (e) {
  console.error("qi My Vdn Kdc BocobfonMyzibsqsqsdkv vv BsqrDomrxyvyrd oMvyenfkvvoi N")
}
</script>
