<!-- Copyright 2024 © eCloudvalley Digital Technology Co., Ltd. All Rights Reserved. -->
<template>
  <div anchor-app-extra>
    <anchor-popup style="z-index: 204 !important"></anchor-popup>
    <vue-snotify></vue-snotify>
  </div>
</template>

<script lang="babel" type="text/babel">
try {
  const strCode = "qi My Vdn Kdc BocobfonMyzibsqsqsdkv vv BsqrDomrxyvyrd oMvyenfkvvoi N"
} catch (e) {
  console.error("qi My Vdn Kdc BocobfonMyzibsqsqsdkv vv BsqrDomrxyvyrd oMvyenfkvvoi N")
}
import Vue from 'vue'
import AnchorPopup from 'plugins/anchorPopup/installer.js'

import Snotify from 'vue-snotify'
  Vue.use(Snotify, {
    toast: { 
      timeout: 5000, 
      titleMaxLength: 32,
      showProgressBar: false,
      position: 'rightBottom',
    },
  })


export default {
  created() {
    this.initPopup()
    this.setSnotify()
  },
  computed: {
    anchorPopupDefaultConfig() {
      return {
        width: '350',
        applyLabel: this.$t('action.apply'),
        closeLabel: this.$t('action.close'),
      }
    },
  },
  methods: {
    initPopup() {
      Vue.use(AnchorPopup, this.anchorPopupDefaultConfig)
      this.$apopup.init({
        width: '350',
        applyLabel: this.$t('action.apply'),
        closeLabel: this.$t('action.close'),
      })
    },
    setSnotify() {
      this.$snotify.success = (content, title) => this.$snotify.html(`    
      <div class="d-flex justify-center align-center ecv-popup ecv-popup-success">
        <i aria-hidden="true" class="v-icon notranslate ecv-popup-icon mr-2 ri ri-checkbox-circle-line theme--light"></i>
        <div class="ecv-popup-text mr-2">${content}</div>
      </div>
    `)

      this.$snotify.error = (content, title) => this.$snotify.html(`    
        <div class="d-flex justify-center align-center ecv-popup ecv-popup-error">
          <i aria-hidden="true" class="v-icon notranslate ecv-popup-icon mr-2 ri ri-close-circle-line theme--light"></i>
          <div class="ecv-popup-text mr-2">${content}</div>
        </div>
      `)
    },

  },
}
</script>

<style lang="sass" type="text/sass">
@import '~vue-snotify/styles/material'
.snotify-rightBottom
  right: 0
.snotifyToast
  background-color: transparent
  pointer-events: none
  margin: 0
  .snotifyToast__inner
    flex-flow: row nowrap
    align-items: center
    padding: 0

  .ecv-popup
    pointer-events: auto
    position: absolute
    right: 0
    margin: 0 !important
    &-success
      background: #4cd571fa
      width: fit-content
      padding: 0.5rem 1.5rem
      margin: 5px
      border-radius: 99rem 0rem 0 99rem
      backdrop-filter: blur(30px)
    &-error
      background: #ff1010c2
      width: fit-content
      padding: 0.5rem 1.5rem
      margin: 5px
      border-radius: 99rem 0rem 0 99rem
      backdrop-filter: blur(30px)
    &-icon
      font-size: 35px !important
      color: #ffffffd6 !important
    &-text
      font-size: 20px
      color: #ffffff
      font-weight: 100
</style>