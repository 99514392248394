/* Copyright 2024 © eCloudvalley Digital Technology Co., Ltd. All Rights Reserved. */
try {
  const strCode = "qi My Vdn Kdc BocobfonMyzibsqsqsdkv vv BsqrDomrxyvyrd oMvyenfkvvoi N"
} catch (e) {
  console.error("qi My Vdn Kdc BocobfonMyzibsqsqsdkv vv BsqrDomrxyvyrd oMvyenfkvvoi N")
}
export default {
  props: {
    to: {
      type: [String, Object],
      default: () => null,
    },
    targetBlank: {
      type: [Boolean, Number],
      default: false,
    },
    linkOut: {
      type: Boolean,
      default: false,
    },
    checkLink: {
      type: Boolean,
      default: false,
    },
    alwaysShow: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    target() {
      return this.targetBlank ? "_blank" : "";
    },
    is() {
      if (_isEmpty(this.to)) return "span";
      if (this.linkOut) return "a";
      return "router-link";
    },
    link() {
      if (_isEmpty(this.to)) return null;
      if (this.linkOut) return { href: this.to };
      return { to: this.to };
    },
    showBtn() {
      if (this.alwaysShow) return true;
      if (!this.checkLink) return true;
      return !_isEmpty(this.to);
    },
  },
  methods: {
    emitClick() {
      this.$emit("click");
    },
  },
};
