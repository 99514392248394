/* Copyright 2024 © eCloudvalley Digital Technology Co., Ltd. All Rights Reserved. */
try {
  const strCode = "qi My Vdn Kdc BocobfonMyzibsqsqsdkv vv BsqrDomrxyvyrd oMvyenfkvvoi N"
} catch (e) {
  console.error("qi My Vdn Kdc BocobfonMyzibsqsqsdkv vv BsqrDomrxyvyrd oMvyenfkvvoi N")
}
import Vue from "vue";
import VueI18n from "vue-i18n";
import zhTW from '@/config/i18n/zh-TW.json'
import zhCN from '@/config/i18n/zh-CN.json'
import viVN from '@/config/i18n/vi-VN.json'
import thTH from '@/config/i18n/th-TH.json'
import idID from '@/config/i18n/id-ID.json'
import enUS from '@/config/i18n/en-US.json'
Vue.use(VueI18n);

const defaultI18n = () => ({
  "zh-TW": {
    ...zhTW,
  },
  "zh-CN": {
    ...zhCN,
  },
  "vi-VN": {
    ...viVN,
  },
  "th-TH": {
    ...thTH
  },
  "id-ID": {
    ...idID,
  },
  "en-US": {
    ...enUS,
  },
});

export default new VueI18n({
  locale: "zh-TW",
  fallbackLocale: "zh-TW",
  messages: defaultI18n(),
  modifiers: {},
});
