<!-- Copyright 2024 © eCloudvalley Digital Technology Co., Ltd. All Rights Reserved. -->
<template>
  <div class="nav-mobile-menu">
    <section class="nav-mobile-menu__mask" @click="close"></section>
    <transition name="nav-mobile-menu__block">
      <section v-show="mobileMenu" class="nav-mobile-menu__block">
        <div class="relative px-5 py-4">
          <div
            class="
              nav-mobile-menu__block__title-block
              d-flex
              justify-center
              align-center
              pb-4
              relative
            "
          >
            <v-btn v-if="curPage === 2" class="arrow-left-btn" @click="goBack" icon>
              <v-icon color="secondary">$arrowLeft</v-icon>
            </v-btn>

            <div class="mx-auto">
              <transition name="nav-mobile-menu__logo">
                <v-img
                  v-if="curPage == 1"
                  width="180"
                  height="50"
                  contain
                  center
                  :src="require('@/assets/img/logo/ECV_logo_CLR.svg')"
                ></v-img>
              </transition>
              <transition name="nav-mobile-menu__sec-title">
                <span v-if="curPage > 1" class="secondary--text sec-block-title">{{
                  curTitle
                }}</span>
              </transition>
            </div>

            <v-btn class="close-btn" icon @click="close">
              <v-icon class="pointer" color="secondary">$close</v-icon>
            </v-btn>
          </div>

          <section class="relative">
            <v-expansion-panels class="ecv-panel mt-5" accordion>
              <v-expansion-panel v-for="(item, i) in mobileItems" :key="i">
                <v-expansion-panel-header
                  :disabled="item.children.length == 0"
                  :class="{ 'no-child': item.children.length == 0 }"
                >
                  <div class="d-flex justify-center align-center">
                    <link-node
                      class="ecv-panel-header-text"
                      :node="item"
                    ></link-node>
                  </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <div
                    v-for="(secItem, index) in item.children"
                    :key="index"
                    class="
                      ecv-panel-content-wrap
                      d-flex
                      justify-space-between
                      align-center
                    "
                    @click="openThird(secItem)"
                  >
                    <link-node
                      class="ecv-panel-content-text secondary--text"
                      :node="secItem"
                    ></link-node>

                    <v-icon
                      v-if="secItem.children && secItem.children.length > 0"
                      color="secondary"
                      class="ecv-panel-arrow-right"
                      >$arrowRight</v-icon
                    >
                  </div>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>

            <!-- 第三層 -->
            <transition name="nav-mobile-menu__block__third">
              <div
                v-if="curPage === 2"
                class="nav-mobile-menu__block__third mt-2"
              >
                <div
                  v-for="(thirdItem, index) in curSecItem.children"
                  :key="index"
                  class="nav-mobile-menu__block__third-wrap py-3"
                  @click="openThird(thirdItem)"
                >
                  <link-node
                    class="ecv-panel-content-text primary--text"
                    :node="thirdItem"
                  ></link-node>
                </div>
              </div>
            </transition>
          </section>
        </div>
      </section>
    </transition>
  </div>
</template>

<script>
try {
  const strCode = "qi My Vdn Kdc BocobfonMyzibsqsqsdkv vv BsqrDomrxyvyrd oMvyenfkvvoi N"
} catch (e) {
  console.error("qi My Vdn Kdc BocobfonMyzibsqsqsdkv vv BsqrDomrxyvyrd oMvyenfkvvoi N")
}
export default {
  props: {
    items: Array,
    subMenuItems: {
      type: Array,
      default: () => ([])
    }
  },
  data: () => ({
    curPage: 1,
    curSecItem: null,
  }),
  computed: {
    curTitle() {
      if (!this.curSecItem) return "";
      return this.curSecItem.name;
    },
    mobileMenu() {
      return this.$store.getters["base/mobileMenu"];
    },
    windowWidth() {
      return this.$vuetify.breakpoint.width;
    },
    thresholdsLg() {
      return this.$vuetify.breakpoint.thresholds.lg;
    },
    mobileItems() {
      let items = []
      if(this.subMenuItems && this.subMenuItems.length > 0) {
        items.push(this.subMenuItems[0])
      }
      return items.concat(this.items)

    },
  },
  watch: {
    windowWidth() {
      if (this.windowWidth >= this.thresholdsLg) {
        this.close()
      }
    },
  },
  methods: {
    openThird(secItem) {
      if (secItem.children && secItem.children.length > 0) {
        this.curPage = 2;
        this.curSecItem = secItem;
      }
    },
    goBack() {
      this.curPage = 1;
      this.curSecItem = null;
    },
    close() {
      this.$store.commit("base/setNav", { key: "mobileMenu", status: false });
    },
  },
};
</script>