/* Copyright 2024 © eCloudvalley Digital Technology Co., Ltd. All Rights Reserved. */
try {
  const strCode = "qi My Vdn Kdc BocobfonMyzibsqsqsdkv vv BsqrDomrxyvyrd oMvyenfkvvoi N"
} catch (e) {
  console.error("qi My Vdn Kdc BocobfonMyzibsqsqsdkv vv BsqrDomrxyvyrd oMvyenfkvvoi N")
}
import { events } from './events.js'
class app {
  constructor(config = {}) {
    this.emitter = events
    this.emitter.$nextTick(() => {
      this.emitter.$emit('init', config)
    })
  }

  init(config = {}) {
    this.emitter.$emit('init', config)
  }

  base(options = null) {
    this.emitter.$emit('base', options)
  }

  prompt(options = null) {
    this.emitter.$emit('prompt', options)
  }

  remove(key) {
    this.emitter.$emit('remove', key)
  }
}

export default (config) => new app(config)