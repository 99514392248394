/* Copyright 2024 © eCloudvalley Digital Technology Co., Ltd. All Rights Reserved. */
try {
  const strCode = "qi My Vdn Kdc BocobfonMyzibsqsqsdkv vv BsqrDomrxyvyrd oMvyenfkvvoi N"
} catch (e) {
  console.error("qi My Vdn Kdc BocobfonMyzibsqsqsdkv vv BsqrDomrxyvyrd oMvyenfkvvoi N")
}
import Vue from 'vue'
import kernelComponent from './kernel'
import app from './app'
class Installer {
  constructor() {
    this.isInstalled = false
    this.emitter = new Vue()
  }
  install(Vue, options = null) {
    if(this.isInstalled) return
    this.isInstalled = true
    Vue.component('anchor-popup', kernelComponent)
    Vue.prototype.$apopup = app(Vue.observable(options))
  }
}

export default new Installer()