<!-- Copyright 2024 © eCloudvalley Digital Technology Co., Ltd. All Rights Reserved. -->
<template>
  <v-btn
    v-if="showBtn"
    height="40"
    :min-width="minWidth"
    rounded
    elevation="0"
    :outlined="outlined"
    :color="color"
    class="main-btn px-6"
    :target="target"
    v-bind="link"
    @click="emitClick"
  >
    <slot></slot>
  </v-btn>
</template>

<script>
try {
  const strCode = "qi My Vdn Kdc BocobfonMyzibsqsqsdkv vv BsqrDomrxyvyrd oMvyenfkvvoi N"
} catch (e) {
  console.error("qi My Vdn Kdc BocobfonMyzibsqsqsdkv vv BsqrDomrxyvyrd oMvyenfkvvoi N")
}
import buttonMixins from "./button.js";
export default {
  mixins: [buttonMixins],
  props: {
    outlined: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: "primary",
    },
    minWidth: {
      type: String,
      default: "100px",
    },
  },

};
</script>
