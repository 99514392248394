export default [
    {
        path: "/wp-content/uploads/2021/07/%E4%BC%8A%E9%9B%B2%E8%B0%B72021%E6%B0%B8%E7%BA%8C%E5%A0%B1%E5%91%8A%E6%9B%B8-1.pdf",
        decodePath: "/wp-content/uploads/2021/07/伊雲谷2021永續報告書-1.pdf",
        beforeEnter(to, from, next) {
            window.location.replace("https://d1cheirdp8wubm.cloudfront.net/ecv-production/file/default/160898711862fc4ed6191a1.pdf")
        }
    },
    {
        path: "/wp-content/uploads/2018/05/110%E5%B9%B4%E5%BA%A6%E4%BC%8A%E9%9B%B2%E8%B0%B7%E5%B9%B4%E5%A0%B1.pdf",
        decodePath: "/wp-content/uploads/2018/05/110年度伊雲谷年報.pdf",
        beforeEnter(to, from, next) {
            window.location.replace("https://d1cheirdp8wubm.cloudfront.net/ecv-production/file/default/164152705462a9aa6d4be62.pdf")
        }
    },
    {
        path: "/download/ecv-ia-b-cm238_%E8%91%A3%E4%BA%8B%E9%81%B8%E4%BB%BB%E7%A8%8B%E5%BA%8Fv1-2_20210707-%E5%85%AC%E5%91%8A%E7%89%88",
        decodePath: "/download/ecv-ia-b-cm238_董事選任程序v1-2_20210707-公告版",
        beforeEnter(to, from, next) {
            window.location.replace("https://d1cheirdp8wubm.cloudfront.net/ecv-production/file/default/1639116656625eb105150d5.pdf")
        }
    },
    {
        path: "/download/ecv-ia-b-cm216_%E8%91%A3%E4%BA%8B%E6%9C%83%E8%AD%B0%E4%BA%8B%E8%A6%8F%E5%89%87v1-5_20210407-%E5%85%AC%E5%91%8A%E7%89%88",
        decodePath: "/download/ecv-ia-b-cm216_董事會議事規則v1-5_20210407-公告版",
        beforeEnter(to, from, next) {
            window.location.replace("https://d1cheirdp8wubm.cloudfront.net/ecv-production/file/default/1778588154625eb110d31e6.pdf")
        }
    },
    {
        path: "/download/ecv-ia-cm930_%E7%8D%A8%E7%AB%8B%E8%91%A3%E4%BA%8B%E4%B9%8B%E8%81%B7%E8%B2%AC%E7%AF%84%E7%96%87%E8%A6%8F%E5%89%87_20201223",
        decodePath: "/download/ecv-ia-cm930_獨立董事之職責範疇規則_20201223",
        beforeEnter(to, from, next) {
            window.location.replace("https://d1cheirdp8wubm.cloudfront.net/ecv-production/file/default/2054770897625eb1211a715.pdf")
        }
    },
    {
        path: "/download/ecv-ia-cm929_%E5%AF%A9%E8%A8%88%E5%A7%94%E5%93%A1%E6%9C%83%E7%B5%84%E7%B9%94%E8%A6%8F%E7%A8%8B_20201223",
        decodePath: "/download/ecv-ia-cm929_審計委員會組織規程_20201223",
        beforeEnter(to, from, next) {
            window.location.replace("https://d1cheirdp8wubm.cloudfront.net/ecv-production/file/default/1002199709625eb1296e166.pdf")
        }
    },
    {
        path: "/download/ecv-ia-b-cm226_%E8%96%AA%E8%B3%87%E5%A0%B1%E9%85%AC%E5%A7%94%E5%93%A1%E6%9C%83%E7%B5%84%E7%B9%94%E8%A6%8F%E7%A8%8Bv1-4_20210407",
        decodePath: "/download/ecv-ia-b-cm226_薪資報酬委員會組織規程v1-4_20210407",
        beforeEnter(to, from, next) {
            window.location.replace("https://d1cheirdp8wubm.cloudfront.net/ecv-production/file/default/1016554148625eb130cc8fe.pdf")
        }
    },
    {
        path: "/download/%E5%85%AC%E5%8F%B8%E6%B2%BB%E7%90%86%E5%AF%A6%E5%8B%99%E5%AE%88%E5%89%87v1-3_20220329",
        decodePath: "/download/公司治理實務守則v1-3_20220329",
        beforeEnter(to, from, next) {
            window.location.replace("https://d1cheirdp8wubm.cloudfront.net/ecv-production/file/default/1585291570625eb15aee154.pdf")
        }
    },
    {
        path: "/download/%E8%AA%A0%E4%BF%A1%E7%B6%93%E7%87%9F%E5%AE%88%E5%89%87-2",
        decodePath: "/download/誠信經營守則-2",
        beforeEnter(to, from, next) {
            window.location.replace("https://d1cheirdp8wubm.cloudfront.net/ecv-production/file/default/522567627625eb164af03b.pdf")
        }
    },
    {
        path: "/download/ecv-ia-b-cg204_%E9%81%93%E5%BE%B7%E8%A1%8C%E7%82%BA%E6%BA%96%E5%89%87v1-1_20201223-%E5%85%AC%E5%91%8A%E7%89%88",
        decodePath: "/download/ecv-ia-b-cg204_道德行為準則v1-1_20201223-公告版",
        beforeEnter(to, from, next) {
            window.location.replace("https://d1cheirdp8wubm.cloudfront.net/ecv-production/file/default/2020589199625eb16ae9616.pdf")
        }
    },
    {
        path: "/download/%E4%BC%81%E6%A5%AD%E7%A4%BE%E6%9C%83%E8%B2%AC%E4%BB%BB%E5%AF%A6%E5%8B%99%E5%AE%88%E5%89%87-2",
        decodePath: "/download/企業社會責任實務守則-2",
        beforeEnter(to, from, next) {
            window.location.replace("https://d1cheirdp8wubm.cloudfront.net/ecv-production/file/default/101838082762fc4de036c73.pdf")
        }
    }
]