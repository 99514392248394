<template>
    <section id="ecvNavbar__submenu" class="ecvNavbar__submenu">
      <div
        class="ecvNavbar__submenu__content d-flex"
      >
      <!-- 第2層 -->
        <sub-menu-second
            v-for="(secondaryItem, secondaryIndex) in secondaryItems" 
            :key="secondaryItem.id" 
            :data="secondaryItem"
            :index="secondaryIndex"
        ></sub-menu-second>


      </div>
    </section>
</template>

<script>
import subMenuSecond from './subMenuSecond.vue'
export default {
  components: { subMenuSecond },
    props: {
        posX: {
            type: String,
            default: 'auto',
        },
        // 第一層
        items: {
            type: Array,
            default: () => []
        }
    },
    data: () => ({
 
    }),
    computed: {
        secondaryItems() {
            if(!this.items[0]) return []
            return this.items[0].children
        },
        
    },
    methods: {

    },
}
</script>
