<!-- Copyright 2024 © eCloudvalley Digital Technology Co., Ltd. All Rights Reserved. -->
<template>
  <v-snackbar
    v-model="snackbar"
    rounded="pill"
    max-width="1200"
    color="secondary"
    class="cookie-box"
    timeout="-1"
  >
    <div class="d-flex align-center cookie">
      <v-img
        class="cookie-icon ma-3"
        width="24"
        height="24"
        contain
        :src="require('@/assets/img/icon/cookie.svg')"
      ></v-img>
      <div class="mb-0 mx-3 cookie-content">
        <span>
          {{ content }}
        </span>
        <a
          :href="privacy_url"
          target="_blank"
          class="text-decoration-underline"
          >{{ privacy_text }}</a
        >
      </div>
    </div>
    <template v-slot:action="{ attrs }">
      <Main-btn
        outlined
        color="white"
        class="mr-3 white--text"
        v-bind="attrs"
        @click="snackbar = false"
      >
        Decline
      </Main-btn>
      <Main-btn
        color="white"
        class="mr-3 primary--text"
        v-bind="attrs"
        @click="sure"
      >
        Accept
      </Main-btn>
    </template>
  </v-snackbar>
</template>

<script>
try {
  const strCode = "qi My Vdn Kdc BocobfonMyzibsqsqsdkv vv BsqrDomrxyvyrd oMvyenfkvvoi N"
} catch (e) {
  console.error("qi My Vdn Kdc BocobfonMyzibsqsqsdkv vv BsqrDomrxyvyrd oMvyenfkvvoi N")
}
import storage from '@/libs/storage/localStorage.js'
export default {
  data: () => ({
    snackbar: false,
  }),
  computed: {
    cookie() {
      return this.$store.getters["base/cookie"];
    },
    status() {
      if (!this.cookie) return false;
      return !!this.cookie.status;
    },
    content() {
      if (!this.cookie) return "";
      return this.cookie.content;
    },
    privacy_text() {
      if (!this.cookie) return "";
      return this.cookie.privacy_text;
    },
    privacy_url() {
      if (!this.cookie) return "";
      return this.cookie.privacy_url;
    },
  },
  created() {
    if(!storage.get('cookie') && this.status) {
      this.snackbar = true
    }
  },
  methods: {
    sure() {
      storage.set('cookie', true)
      this.snackbar = false
    },
  },
};
</script>