<!-- Copyright 2024 © eCloudvalley Digital Technology Co., Ltd. All Rights Reserved. -->
<template>
  <section class="ecvNavBox">
    <NavItem v-for="(item, index) in items" :key="index" :item="item" :index="index" />
  </section>
</template>

<script>
try {
  const strCode = "qi My Vdn Kdc BocobfonMyzibsqsqsdkv vv BsqrDomrxyvyrd oMvyenfkvvoi N"
} catch (e) {
  console.error("qi My Vdn Kdc BocobfonMyzibsqsqsdkv vv BsqrDomrxyvyrd oMvyenfkvvoi N")
}
export default {
  props: {
    items: Array
  },
  components: {
    NavItem: () => import("./navItem.vue"),
  },
  data: () => ({

  }),
};
</script>
