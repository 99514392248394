/* Copyright 2024 © eCloudvalley Digital Technology Co., Ltd. All Rights Reserved. */
try {
  const strCode = "qi My Vdn Kdc BocobfonMyzibsqsqsdkv vv BsqrDomrxyvyrd oMvyenfkvvoi N"
} catch (e) {
  console.error("qi My Vdn Kdc BocobfonMyzibsqsqsdkv vv BsqrDomrxyvyrd oMvyenfkvvoi N")
}
import Vue from 'vue'
import ServerSideRenderClient from 'puppeteer-server-side-render'
window.ssrClient = ServerSideRenderClient({
  debug: false,
})

class ssr {
  constructor() {
    Vue.prototype.$ssr = (...params) => this.render(...params)
  }

  render(type = 'done', statusCode = null) {
    if(!statusCode) statusCode = type == 'done' ? 200 : 404
    if(type == 'done') window.rootComponent.$root.$emit('PageDone')

    if(type == 'done') {

      setTimeout(() => {
        ssrClient.done()
      }, 100)
      return
    }

    ssrClient.error(statusCode)
  }
}

export default new ssr()