/* Copyright 2024 © eCloudvalley Digital Technology Co., Ltd. All Rights Reserved. */
try {
  const strCode = "qi My Vdn Kdc BocobfonMyzibsqsqsdkv vv BsqrDomrxyvyrd oMvyenfkvvoi N"
} catch (e) {
  console.error("qi My Vdn Kdc BocobfonMyzibsqsqsdkv vv BsqrDomrxyvyrd oMvyenfkvvoi N")
}
import qs from 'qs'
import axios from 'axios'
import { apibase } from 'config/apibase.json'
import realApi from 'libs/api/realApi'
import mockApi from 'libs/api/mockApi'
import fingerprint from 'fingerprintjs'
import storage from 'libs/storage/localStorage.js'


class api {
  async _init() {
    this.apiCounter = 0
    this.timeout = null
    const { default: mockHelper } = await import('libs/mock/mockHelper.js')
    this.mockHelper = mockHelper
    this.fingerprint = new fingerprint().get()
  }

  async _checkRequestDone() {
    const quantity = await this._getPendingRequest()
    if(quantity == 0) return
  }

  _getPendingRequest() {
    const self = this
    if(this.timeout) {
      window.clearTimeout(this.timeout)
      this.timeout = null
    }

    if(this.apiCounter != 0) {
      return new Promise(resolve => {
        resolve(self.apiCounter)
      })
    }

    return new Promise(resolve => {
      self.timeout = window.setTimeout(() => {
        resolve(self.apiCounter)
      }, 200)
    })
  }

  _getLanguage() {
    if(!window.i18nLibrary) return null
    if(!window.i18nLibrary.getClientLanguage) return null
    return window.i18nLibrary.getClientLanguage()
  }

  getDefaultConfig(config) {
    let requestConfig = {
      baseURL: config.baseURL || apibase,
      url: config.url || '',
      method: config.type || 'get',
      params: null,
      data: null,
      headers: {
        Fingerprint: this.fingerprint,
      },
      responseType: config.responseType || 'json',
      name: `api.${config.name}`,
      onDownloadProgress: event => {
        if(typeof config.onDownloadProgress != 'function') return
        const progress = Math.floor(100*(event.loaded/event.total))
        config.onDownloadProgress({
          progress,
          event,
        })
      },
      onUploadProgress: event => {
        if(typeof config.onUploadProgress != 'function') return
        const progress = Math.floor(100*(event.loaded/event.total))
        config.onUploadProgress({
          progress,
          event,
        })
      }
    }

    if(requestConfig.method == 'get') {
      requestConfig.params = config.params || null
      requestConfig.paramsSerializer = params => qs.stringify(params, { arrayFormat: 'indices' })
    }
    if(requestConfig.method != 'get') {
      requestConfig.data = config.params || null
    }

    // 自訂authorization(串第三方api會需要)
    if(config.authorization) {
      requestConfig.headers.Authorization =`Bearer ${config.authorization}`
    }

    if(window.siteId) {
      requestConfig.headers.site =`${window.siteId}`
    }

    // martipart
    if(config.martipart) {
      requestConfig.headers['Content-Type'] = 'multipart/form-data'
      requestConfig.data = config.data
    }

    const language = this._getLanguage()
    if(language) {
      requestConfig.headers['Language'] = language
    }

    return requestConfig
  }

  _isCacheApi(config) {
    if(!config.cache) return false
    if(!config.cache.key) return false
    if(!config.cache.latestVersion) return false
    return true
  }

  _loadFromStorage(cacheConfig) {
    return storage.get(cacheConfig.key)
  }

  _localCacheVersion(cacheConfig) {
    if(!cacheConfig) return -1
    const cacheData = storage.get(cacheConfig.key)
    if(!cacheData) return -1
    return parseInt(cacheData.version)
  }

  // 將cache存至storage
  _storeCacheData(cacheConfig, data) {
    if(data.version == undefined) {
      console.error('store api cache fail, cause cache data not has version property')
      return
    }

    if(cacheConfig.cache != true) return

    try {
      storage.set(cacheConfig.key, data)
    } catch (error) {
      console.warn('store api cache fail', error)
      storage.delete(cacheConfig.key)
    }
  }

  _needUpgradeCache(config) {
    if(config.cache.cache != true) {
      storage.delete(config.cache.key)
      return true
    }

    const localCacheVersion = this._localCacheVersion(config.cache)
    if(localCacheVersion <= 0) return true
    if(localCacheVersion < config.cache.latestVersion) return true
    return false
  }

  request(config) {
    const self = this
    const isCacheApi = this._isCacheApi(config)

    // 可使用cache的api, 有cache就回傳cache api(promise)
    if(isCacheApi && this._needUpgradeCache(config) === false) {
      const cacheData = this._loadFromStorage(config.cache)
      return new Promise(resolve => resolve(cacheData))
    }

    const axiosConfig = self.getDefaultConfig(config)
    this.apiCounter++
    return new Promise(async (resolve, reject) => {
      try {
        let result = await axios(axiosConfig)
        let resolveContent = axiosConfig.responseType == 'blob'
          ? {
            data: result.data,
            status: result.status,
            xhr: result.request,
          }
          : result.data

        if(isCacheApi) self._storeCacheData(config.cache, resolveContent) // 存下cache資料
        self.apiCounter--
        self._checkRequestDone()
        resolve(resolveContent)
      } catch(error) {
        let response = error.response
        console.error(response)
        self.apiCounter--
        self._checkRequestDone()
        reject(response)
      }
    })
  }

  _isGif(formData) {
    if(!formData) return
    if(!formData.get('file')) return
    return formData.get('file').type === 'image/gif'
  }
}

export default async () => {
  let apiObject = new api()
  await apiObject._init()

  // 跑測試時不使用實際api
  if(process.env.NODE_ENV != 'testing') {
    realApi(apiObject)
  }
  mockApi(apiObject)
  return apiObject
}