<!-- Copyright 2024 © eCloudvalley Digital Technology Co., Ltd. All Rights Reserved. -->
<template>
  <div>
    <svg
      id="ecvWaveFooter"
      class="ecvWaveFooter"
      data-name="ecvWaveFooter"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1920 30"
      preserveAspectRatio="xMidYMid slice"
    >
      <path
        id="Path_542"
        data-name="Path 542"
        class="ecvWaveFooter1"
        d="M1920,22.75S1695.06,15,1439.48,15,725.79,30,586.76,30,164,29,0,22.5V30H1920Z"
        transform="translate(0 -0.12)"
      />
      <path
        id="Path_512"
        data-name="Path 512"
        class="ecvWaveFooter2"
        d="M0,15.6S224.94.12,480.52.12s713.69,30,852.72,30S1756,28.07,1920,15.12v15H0Z"
        transform="translate(0 -0.12)"
      />
    </svg>

    <!-- ------------proMax--------------- -->
    <svg
      id="ecvWaveFooterProMaxMobile"
      class="ecvWaveFooterProMaxMobile"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 428 25"
      preserveAspectRatio="xMidYMid slice"
    >
      <path
        class="ecvWaveFooterMobile1"
        d="M428,14.19s-73.72-9.69-130.68-9.69-135.52,12.32-166.51,12.32S36.56,13.7,0,11.35v13.65H428V14.19Z"
      />
      <path
        class="ecvWaveFooterMobile2"
        d="M0,8.23C35.44,2.91,71.25,.16,107.11,0c56.98,0,108.03,15.96,190.08,15.96,30.99,0,94.25-3.88,130.81-10.78V25H0V8.23Z"
      />
    </svg>

    <!-- -------------11-------------- -->

    <svg
      id="ecvWaveFooter11Mobile"
      class="ecvWaveFooter11Mobile"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 414 25"
      preserveAspectRatio="xMidYMid slice"
    >
      <path
        class="ecvWaveFooterMobile1"
        d="M414,13.73s-71.31-9.37-126.41-9.37-131.09,11.92-161.06,11.92S35.36,13.25,0,10.98v14.02H414V13.73Z"
      />
      <path
        class="ecvWaveFooterMobile2"
        d="M0,7.96C34.28,2.81,68.92,.15,103.61,0c55.12,0,104.5,15.44,183.86,15.44,29.98,0,91.17-3.75,126.53-10.43V25H0V7.96Z"
      />
    </svg>

    <!-- ------------12pro--------------- -->

    <svg
      id="ecvWaveFooter12Mobile"
      class="ecvWaveFooter12Mobile"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 390 25"
      preserveAspectRatio="xMidYMid slice"
    >
      <path
        class="ecvWaveFooterMobile1"
        d="M390,12.93s-67.17-8.83-119.08-8.83S147.43,15.33,119.2,15.33,33.31,12.48,0,10.34v14.66H390V12.93Z"
      />
      <path
        class="ecvWaveFooterMobile2"
        d="M0,7.5C32.29,2.65,64.92,.15,97.6,0c51.92,0,98.44,14.54,173.2,14.54,28.24,0,85.88-3.54,119.2-9.82V25H0V7.5Z"
      />
    </svg>

    <!-- --------------xs，se------------- -->

    <svg
      id="ecvWaveFooterMobile"
      class="ecvWaveFooterMobile"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 375 22"
      preserveAspectRatio="xMidYMid slice"
    >
      <path
        class="ecvWaveFooterMobile1"
        d="M375,12.69S310.41,3,260.5,3,141.76,15.32,114.61,15.32,32.03,12.2,0,9.85v12.15H375V12.69Z"
      />
      <path
        class="ecvWaveFooterMobile2"
        d="M0,7.74C31.05,2.74,62.43,.15,93.85,0c49.92,0,94.65,15,166.54,15,27.15,0,82.58-3.65,114.61-10.13V22H0V7.74Z"
      />
    </svg>
  </div>
</template>

<script>
try {
  const strCode = "qi My Vdn Kdc BocobfonMyzibsqsqsdkv vv BsqrDomrxyvyrd oMvyenfkvvoi N"
} catch (e) {
  console.error("qi My Vdn Kdc BocobfonMyzibsqsqsdkv vv BsqrDomrxyvyrd oMvyenfkvvoi N")
}
export default {
  data: () => ({
    reloadKey: 0,
  }),
  async mounted() {
    await this.$helper.delay(0.5);
    this.reloadKey += 1;
  },
};
</script>

<style lang="sass">
.footer404
  .ecvWaveFooter
    background-color: #003254
  .ecvWaveFooterMobile
    background-color: #003254
</style>